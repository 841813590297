import { Typography, useTheme } from '@material-ui/core'
import { Pie } from '@visx/shape'
import { Text } from '@visx/text'
import { useTranslation } from 'react-i18next'
import { getUseVODStore } from '../VODController'
import { useCraneStore } from './CranePicksController'
import { darkTheme } from '../../../styles/theme'
import React from 'react'

export const CraneReportsUtilizationPie = () => {
  const { t } = useTranslation()
  const theme = useTheme()
  const useVODStore = getUseVODStore()
  const craneStore = useCraneStore()

  const date = useVODStore((state) => state.videoDate)
  const days = craneStore((state) => state.days)

  const currentDayData = days.get(date.toISODate())

  if (!currentDayData) return null

  return (
    <div>
      <svg
        width={'100%'}
        height={200}
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid meet"
      >
        <Text
          x={50}
          y={50}
          fill={theme.palette.text.primary}
          textAnchor="middle"
          verticalAnchor="middle"
          fontSize={30}
        >{`${(currentDayData.utilization * 100).toFixed(0)}%`}</Text>

        <Pie
          top={50}
          left={50}
          data={[currentDayData.utilization]}
          pieValue={(d) => d}
          startAngle={0}
          endAngle={currentDayData.utilization * Math.PI * 2}
          innerRadius={35}
          outerRadius={38}
          fill={darkTheme.palette.secondary.main}
        />

        <Pie
          top={50}
          left={50}
          data={[currentDayData.utilizationValueAdded]}
          pieValue={(d) => d}
          startAngle={0}
          endAngle={currentDayData.utilizationValueAdded * Math.PI * 2}
          innerRadius={40}
          outerRadius={50}
          fill={darkTheme.palette.success.main}
        />
        <Pie
          top={50}
          left={50}
          data={[currentDayData.utilizationNonValueAdded]}
          pieValue={(d) => d}
          startAngle={currentDayData.utilizationValueAdded * Math.PI * 2}
          endAngle={
            (currentDayData.utilizationValueAdded +
              currentDayData.utilizationNonValueAdded) *
            Math.PI *
            2
          }
          innerRadius={40}
          outerRadius={50}
          fill={darkTheme.palette.warning.main}
        />
        <Pie
          top={50}
          left={50}
          data={[1 - currentDayData.utilization]}
          pieValue={(d) => d}
          startAngle={
            (currentDayData.utilizationValueAdded +
              currentDayData.utilizationNonValueAdded) *
            Math.PI *
            2
          }
          endAngle={Math.PI * 2}
          innerRadius={40}
          outerRadius={50}
          fill={darkTheme.palette.error.main}
        />
      </svg>
      <div>
        <Typography variant="h6">{t('utilization')}</Typography>

        {[
          {
            name: `${(currentDayData.utilization * 100).toFixed(0) || '0'}% ${t(
              'utilization'
            )}`,
            color: darkTheme.palette.secondary.main,
            inset: false,
          },
          {
            name: `${
              (currentDayData.utilizationValueAdded * 100).toFixed(0) || '0'
            }% ${t('value_added')}`,
            color: darkTheme.palette.success.main,
            inset: true,
          },
          {
            name: `${
              (currentDayData.utilizationNonValueAdded * 100).toFixed(0) || '0'
            }% ${t('non_value_added')}`,
            color: darkTheme.palette.warning.main,
            inset: true,
          },
          {
            name: `${
              ((1 - currentDayData.utilization) * 100).toFixed(0) || '0'
            }% ${t('inactive')}`,
            color: darkTheme.palette.error.main,
            inset: false,
          },
        ].map((slice) => (
          <div
            key={slice.name}
            style={{
              display: 'flex',
              alignItems: 'center',
              whiteSpace: 'nowrap',
            }}
          >
            <Typography
              variant="body1"
              style={{
                textAlign: 'center',
                fontSize: 14,
                marginLeft: slice.inset ? 16 : 0,
              }}
            >
              <span
                style={{
                  display: 'inline-block',
                  width: 8,
                  height: 8,
                  borderRadius: '50%',
                  backgroundColor: slice.color,
                  marginRight: 8,
                  WebkitPrintColorAdjust: 'exact',
                }}
              ></span>
              {slice.name || 'Unspecified'}
            </Typography>
          </div>
        ))}
      </div>
    </div>
  )
}
