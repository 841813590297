import {
  createStyles,
  Grid,
  IconButton,
  makeStyles,
  Typography,
  useTheme,
} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import { Axis } from '@visx/axis'
import { curveLinear } from '@visx/curve'
import { Group } from '@visx/group'
import { scaleLinear, scaleTime } from '@visx/scale'
import { Bar, LinePath } from '@visx/shape'
import { Text } from '@visx/text'
import { useMemo } from 'react'
import { useMeasure } from 'react-use'
import { getUseVODStore } from '../VODController'
import { useCraneStore } from './CranePicksController'

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      bar: {
        transition: 'all 0.2s',
        cursor: 'pointer',
        '&:hover': {
          opacity: 0.8,
        },
      },
    }),
  {
    name: 'CraneHistoryPanel',
  }
)

const chartHeight = 200

export const CraneHistoryPanel = () => {
  const classes = useStyles()
  const craneStore = useCraneStore()
  const useVODStore = getUseVODStore()
  const theme = useTheme()

  const gotoVideo = useVODStore((state) => state.gotoVideo)

  const date = useVODStore((state) => state.videoDate)
  const days = craneStore((state) => state.days)
  const weeks = craneStore((state) => state.weeks)
  const projectConfig = craneStore((state) => state.projectConfig)

  const daysArray = Array.from(days.values()).sort((a, b) => {
    return a.date.toMillis() - b.date.toMillis()
  })

  const [ref, { width, height }] = useMeasure<HTMLDivElement>()

  // scales, memoize for performance
  const xScale = useMemo(
    () =>
      scaleTime<number>({
        range: [0, 400],
        round: true,
        domain: [
          date
            .setZone('system', { keepLocalTime: true })
            .minus({ days: 9.5 })
            .toJSDate(),
          date
            .setZone('system', { keepLocalTime: true })
            .plus({ days: 0.5 })
            .toJSDate(),
        ],
      }),
    [date, width]
  )
  const yScale = useMemo(
    () =>
      scaleLinear<number>({
        range: [0, 180],
        round: true,
        domain: [
          0,
          Math.max(
            ...daysArray.map((d) => {
              if (projectConfig?.utilization_history_chart_type === 'bar') {
                return d.totalDuration.as('hours')
              } else if (
                projectConfig?.utilization_history_chart_type === 'line'
              ) {
                return 1
              } else {
                return 1
              }
            })
          ),
        ],
      }),
    [daysArray, height, projectConfig]
  )

  const chartWidth = 400
  const barWidth = Math.floor(chartWidth / 10)

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} justifyContent="space-between" container spacing={1}>
        <Grid item>
          <Typography variant="h5" display="inline">
            Utilization History
          </Typography>
          {projectConfig?.utilization_history_chart_type === 'bar' && (
            <>
              <span>
                <span
                  style={{
                    marginLeft: 10,
                    display: 'inline-block',
                    width: 10,
                    height: 10,
                    borderRadius: 5,
                    backgroundColor: theme.palette.success.main,
                  }}
                ></span>{' '}
                Active
              </span>{' '}
              <span>
                <span
                  style={{
                    marginLeft: 10,
                    display: 'inline-block',
                    width: 10,
                    height: 10,
                    borderRadius: 5,
                    backgroundColor: theme.palette.error.main,
                  }}
                ></span>{' '}
                Idle
              </span>
              <span>
                <span
                  style={{
                    marginLeft: 10,
                    display: 'inline-block',
                    width: 10,
                    height: 1,
                    backgroundColor: theme.palette.warning.main,
                    verticalAlign: 'middle',
                  }}
                ></span>{' '}
                9 hours
              </span>
            </>
          )}
        </Grid>
      </Grid>

      {days.size === 0 ? (
        <Grid item xs={12}>
          <Typography variant="body1">No historical data found</Typography>
        </Grid>
      ) : (
        <>
          <Grid
            item
            xs={12}
            style={{
              position: 'relative',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <IconButton
              size="small"
              onClick={() => {
                const currentDate = useVODStore.getState().videoDate
                gotoVideo({ dateTime: currentDate.minus({ hours: 18 }) })
              }}
            >
              <ArrowBackIcon />
            </IconButton>
            <svg
              style={{
                width: chartWidth,
                height: 320,
              }}
              width={chartWidth}
              height={320}
              // viewBox={`0 0 ${chartWidth} 220`}
              preserveAspectRatio="xMidYMid meet"
            >
              {projectConfig?.utilization_history_chart_type === 'bar' ? (
                <Group top={0} left={0}>
                  {daysArray.map((d) => {
                    const idleHeight = yScale(d.idleDuration.as('hours'))
                    const valueAddedHeight = yScale(
                      d.valueAddedDuration.as('hours')
                    )
                    const nonValueAddedHeight = yScale(
                      d.nonValueAddedDuration.as('hours')
                    )
                    const barX = xScale(
                      d.date
                        .setZone('system', { keepLocalTime: true })
                        .startOf('day')
                    )
                    const valueAddedY = chartHeight - valueAddedHeight
                    const nonValueAddedY =
                      chartHeight - valueAddedHeight - nonValueAddedHeight
                    const idleY =
                      chartHeight -
                      valueAddedHeight -
                      nonValueAddedHeight -
                      idleHeight

                    return (
                      <Group
                        key={d.date.toISO()}
                        left={barX - barWidth / 2}
                        onClick={() => {
                          const firstPick = d.picks[0].startDateTime
                          gotoVideo({ dateTime: firstPick })
                        }}
                        className={classes.bar}
                      >
                        {/* active time bar */}
                        <Bar
                          key={`bar-active-${d.date.toISO()}`}
                          x={3}
                          y={valueAddedY}
                          width={barWidth - 6}
                          height={valueAddedHeight}
                          fill={theme.palette.success.main}
                        />

                        {/* non-value-added time bar */}
                        <Bar
                          key={`bar-active-${d.date.toISO()}`}
                          x={3}
                          y={nonValueAddedY}
                          width={barWidth - 6}
                          height={valueAddedY - nonValueAddedY}
                          fill={theme.palette.warning.main}
                        />

                        {/* idle time bar */}
                        <Bar
                          key={`bar-idle-${d.date.toISO()}`}
                          x={3}
                          y={idleY}
                          width={barWidth - 6}
                          height={idleHeight}
                          fill={theme.palette.error.main}
                        />

                        {/* active time text */}
                        <Text
                          x={barWidth / 2}
                          y={valueAddedY + valueAddedHeight / 2}
                          textAnchor="middle"
                          verticalAnchor="middle"
                          fill={theme.palette.text.primary}
                          fontSize={14}
                          fontWeight="bold"
                          style={{ pointerEvents: 'none' }}
                        >
                          {d.activeDuration.toFormat('h:mm')}
                        </Text>

                        {/* idle time text */}
                        <Text
                          x={barWidth / 2}
                          y={idleY - 10}
                          textAnchor="middle"
                          verticalAnchor="middle"
                          fill={theme.palette.error.main}
                          fontSize={14}
                          fontWeight="bold"
                          style={{ pointerEvents: 'none' }}
                        >
                          {d.idleDuration.toFormat('h:mm')}
                        </Text>
                      </Group>
                    )
                  })}

                  {/* overtime bar */}
                  <Bar
                    x={0}
                    y={chartHeight - yScale(9)}
                    width={400}
                    height={0.5}
                    stroke={theme.palette.warning.main}
                  />
                </Group>
              ) : projectConfig?.utilization_history_chart_type === 'line' ? (
                <Group top={0} left={0}>
                  {/* line connecting circles */}
                  <LinePath
                    curve={curveLinear}
                    data={daysArray}
                    x={(d) => xScale(d.date.toJSDate()) ?? 0}
                    y={(d) => chartHeight - yScale(d.utilization) ?? 0}
                    stroke={theme.palette.text.primary}
                    strokeWidth={1}
                    strokeOpacity={1}
                  />
                  {/* dots and labels */}
                  {daysArray.map((d, i) => (
                    <>
                      <circle
                        key={i + 'circle'}
                        r={4}
                        cx={xScale(d.date.toJSDate())}
                        cy={chartHeight - yScale(d.utilization)}
                        fill={d.utilizationColor}
                      />
                      <text
                        key={i + 'label'}
                        x={xScale(d.date.toJSDate())}
                        y={chartHeight - yScale(d.utilization) - 10}
                        textAnchor="middle"
                        fill={d.utilizationColor}
                        fontSize={14}
                        fontWeight="bold"
                        style={{ pointerEvents: 'none' }}
                      >
                        {(d.utilization * 100).toFixed(0)}%
                      </text>
                    </>
                  ))}
                </Group>
              ) : null}

              {/* axis */}
              <Axis
                top={chartHeight}
                scale={xScale}
                stroke={theme.palette.text.primary}
                tickStroke={theme.palette.text.primary}
                numTicks={daysArray.length}
                tickLabelProps={() => ({
                  fill: theme.palette.text.primary,
                  fontSize: 11,
                  textAnchor: 'middle',
                })}
              />
              {projectConfig?.show_weekly_utilization && (
                <Group top={240} left={0}>
                  <Group>
                    {Array.from(weeks.values()).map((week) => {
                      const weekStartX = xScale(week.systemStartDateTime)
                      const weekEndX = xScale(week.systemEndDateTime)
                      const weekScale = scaleLinear<number>({
                        range: [0, weekEndX - weekStartX],
                        round: true,
                        domain: [
                          0,
                          1000 * 60 * 60 * 24 * 7, // a full week in milliseconds
                        ],
                      })
                      const weekNumber = week.startDateTime.weekNumber

                      const valueAddedWidth = weekScale(
                        week.valueAddedDuration.toMillis()
                      )
                      const nonValueAddedWidth = weekScale(
                        week.nonValueAddedDuration.toMillis()
                      )
                      const idleWidth = weekScale(week.idleDuration.toMillis())
                      const nonValueAddedX = valueAddedWidth
                      const idleX = valueAddedWidth + nonValueAddedWidth

                      return (
                        <Group key={weekNumber} left={weekStartX}>
                          <Bar
                            key={`week-bar-active-${weekNumber}`}
                            x={0}
                            y={0}
                            width={valueAddedWidth}
                            height={barWidth}
                            fill={theme.palette.success.main}
                          />

                          <Bar
                            key={`week-bar-active-${weekNumber}`}
                            x={nonValueAddedX}
                            y={0}
                            width={nonValueAddedWidth}
                            height={barWidth}
                            fill={theme.palette.success.main}
                          />

                          {/* idle time bar */}
                          <Bar
                            key={`week-bar-idle-${weekNumber}`}
                            x={idleX}
                            y={0}
                            width={idleWidth}
                            height={barWidth}
                            fill={theme.palette.error.main}
                            className={classes.bar}
                          />

                          {/* active time text */}
                          <Text
                            x={valueAddedWidth / 2}
                            y={barWidth / 2}
                            textAnchor="middle"
                            verticalAnchor="middle"
                            fill={theme.palette.text.primary}
                            fontSize={14}
                            fontWeight="bold"
                            style={{ pointerEvents: 'none' }}
                          >
                            {week.activeDuration.toFormat('h:mm')}
                          </Text>

                          {/* idle time text */}
                          <Text
                            x={idleX + idleWidth + 6}
                            y={barWidth / 2}
                            textAnchor="start"
                            verticalAnchor="middle"
                            fill={theme.palette.error.main}
                            fontSize={14}
                            fontWeight="bold"
                            style={{ pointerEvents: 'none' }}
                          >
                            {week.idleDuration.toFormat('h:mm')}
                          </Text>

                          <Bar
                            key={`week-bar-baseline-${weekNumber}`}
                            x={-1}
                            y={-16}
                            width={1}
                            height={barWidth + 16}
                            fill={theme.palette.text.primary}
                          />
                        </Group>
                      )
                    })}
                  </Group>
                  <Text
                    x={chartWidth / 2}
                    y={60}
                    textAnchor="middle"
                    verticalAnchor="middle"
                    fill={theme.palette.text.primary}
                    fontSize={14}
                    style={{ pointerEvents: 'none' }}
                  >
                    Weekly hours
                  </Text>
                </Group>
              )}
            </svg>
            <IconButton
              size="small"
              onClick={() => {
                const currentDate = useVODStore.getState().videoDate
                gotoVideo({ dateTime: currentDate.plus({ hours: 30 }) })
              }}
            >
              <ArrowForwardIcon />
            </IconButton>
          </Grid>
        </>
      )}
    </Grid>
  )
}
