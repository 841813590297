import { uniq } from 'lodash'
import React from 'react'
import { Subcontractor } from '../../../api/codegen/typescript-axios'
import { CranePickExtended } from '../../../api/CranePickExtended'
import {
  CranePicksCollection,
  createSumPicksFunction,
} from '../../../api/CranePicksCollection'
import { t } from 'i18next'

export type SubcontractorStats = ({
  picks: CranePickExtended[]
  loadTypes: ({
    loadType: string | null
    picks: CranePickExtended[]
  } & CranePicksCollection)[]
  id?: number | undefined
  name: string
  color?: string | undefined
} & CranePicksCollection)[]

export const useSubcontractorStats = (
  picks: CranePickExtended[],
  subcontractors: Subcontractor[],
  sumPicks: ReturnType<typeof createSumPicksFunction>
): SubcontractorStats => {
  return React.useMemo<SubcontractorStats>(() => {
    const subcontractorStats = Array.from(subcontractors.values())
      .map((subcontractor) => {
        const subPicks = picks.filter(
          (p) => p.subcontractor_id === subcontractor.id
        )

        function loadActionLabel(pick: CranePickExtended) {
          const load = pick.load_data || ''
          const action = pick.action ? `: ${t(`actions.${pick.action}`)}` : ''
          return load + action
        }

        const loadTypes = uniq(subPicks.map((p) => loadActionLabel(p))).map(
          (load_action) => {
            const loadPicks = subPicks.filter(
              (p) => loadActionLabel(p) === load_action
            )

            return sumPicks({
              loadType: load_action,
              picks: loadPicks,
            })
          }
        )

        return sumPicks({
          ...subcontractor,
          picks: subPicks,
          loadTypes: loadTypes,
        })
      })
      .filter((sub) => sub.picks.length > 0)
    return subcontractorStats
  }, [picks, subcontractors])
}
