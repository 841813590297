import { Dialog, IconButton, Paper } from '@material-ui/core'
import {
  createStyles,
  makeStyles,
  ThemeProvider,
  useTheme,
} from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled'
import clsx from 'clsx'
import Hls from 'hls.js'
import { useCallback, useEffect, useRef, useState } from 'react'
import { usePageVisibility } from 'react-page-visibility'
import { useIntersection, useInterval, useUnmount } from 'react-use'
import { useCallbackRef } from 'use-callback-ref'
import { PublicStreamExtended } from '../../api/StreamExtended'
import useStreamLiveStatus from '../../hooks/useStreamLiveStatus'
import { useTouchDevice } from '../../hooks/useTouchDevice'
import { mixins } from '../../styles/mixins'
import { darkTheme } from '../../styles/theme'
import { Loading } from '../Loading'
import { LivePlayerErrorMessages } from './LivePlayerErrorMessages'

// @ts-ignore
import mux from 'mux-embed'
import { useMuxBaseConfig } from '../../hooks/useMuxBaseConfig'
import { useProject } from '../ProjectWrapper'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      borderRadius: 0,
      [theme.breakpoints.up('sm')]: {},
    },
    videoContainer: {
      backgroundColor: '#000',
      position: 'relative',
    },
    ptzContainer: {
      ...mixins.absoluteFill,
    },
    loadingSpinner: {
      color: '#fff',
    },
    '@keyframes fadeIn': {
      from: { opacity: 0 },
      to: { opacity: 1 },
    },
    poster: {
      background: 'center center no-repeat',
      backgroundSize: 'contain',
    },
    layer: {
      ...mixins.absoluteFill,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '40px',
      animation: '$fadeIn',
      animationDuration: '0.3s',
      animationDelay: '2s',
      animationFillMode: 'both',
    },
    errorMessages: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      maxWidth: '100%',
      padding: '6px',
      pointerEvents: 'none',
    },
    overlay: {
      ...mixins.absoluteFill,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    video: {
      ...mixins.absoluteFill,
      display: 'block',
    },
    dialogPaper: {
      height: '100%',
    },
  })
)

export const VideoPlayerPublicHLS = ({
  stream,
  className,
  parentWidth = window.innerWidth,
  extraControls = {},
  disableIntersectionObserver,
  disableRotation,
  showModal = false,
  setModalStreamID,
  slideshowState,
  setSlideshowState,
  slideshowInterval,
  setSlideshowInterval,
  shouldPlay: shouldPlayProp = true,
  nextVideo,
  prevVideo,
}: {
  stream: PublicStreamExtended
  className?: string
  parentWidth?: number
  extraControls?: {
    download?: boolean
    ptz?: boolean
    play?: boolean
    fullScreen?: boolean
    enlarge?: boolean
  }
  disableIntersectionObserver?: boolean
  disableRotation?: boolean
  showModal?: boolean
  setModalStreamID?: (stream?: number) => void
  slideshowState?: boolean
  setSlideshowState?: (b: boolean) => void
  slideshowInterval?: number
  setSlideshowInterval?: (n: number) => void
  shouldPlay?: boolean
  nextVideo?: () => void
  prevVideo?: () => void
}) => {
  const classes = useStyles()
  const project = useProject()
  const muxBaseConfig = useMuxBaseConfig()

  const log = useCallback(
    (...args) => {
      if (process.env.NODE_ENV !== 'production') {
        console.log(stream.name, ...args)
      }
    },
    [stream.name]
  )

  // state
  const videoContainerRef = useCallbackRef<HTMLDivElement>(null, () => {})

  const videoRef = useCallbackRef<HTMLVideoElement>(null, () => {})

  const videoEl = videoRef.current

  const [hlsPlayer, setHlsPlayer] = useState<Hls>()

  const [manifestParsed, setManifestParsed] = useState(false)

  const [buffering, setBuffering] = useState(true)

  const [enlargeWithPtzState, setEnlargeWithPtzState] = useState(false)

  const liveStatus = useStreamLiveStatus(stream)

  const intersection = useIntersection(videoContainerRef, {
    root: null,
    rootMargin: '0px',
    threshold: 0.6,
  })

  const isTouchDevice = useTouchDevice()

  const isVisible = usePageVisibility()
  // end state

  const tryPlay = useCallback(() => {
    const _playPromise = videoRef.current?.play()
    if (_playPromise && _playPromise.catch) {
      _playPromise.catch((err) => {
        // console.warn(err)
      })
    }
  }, [videoRef])

  const previousTime = useRef(0)
  useInterval(() => {
    if (videoRef.current && hlsPlayer) {
      if (
        !videoRef.current.paused &&
        videoRef.current.currentTime === previousTime.current
      ) {
        log('stream frozen setBuffering(true)')
        setBuffering(true)
        hlsPlayer.recoverMediaError()
      } else {
        // log('stream not frozen setBuffering(false)')
        setBuffering(false)
      }
      previousTime.current = videoRef.current.currentTime
    }
  }, 5000)

  // side effects
  // setup player and error handler
  useEffect(() => {
    if (Hls.isSupported() && videoEl && stream.url) {
      var hls = new Hls({
        liveSyncDuration: 3,
        // liveDurationInfinity is buggy, which prevents usage of native controls. Jeep this off for now
        // https://github.com/video-dev/hls.js/issues/1625
        // liveDurationInfinity: true,
        liveMaxLatencyDuration: 5,
        maxBufferLength: 6,
        // liveBackBufferLength: 6,
        fragLoadingMaxRetry: Infinity,
        manifestLoadingMaxRetry: Infinity,
        levelLoadingMaxRetry: Infinity,
        // debug: true,
      })
      hls.loadSource(stream.url)
      hls.attachMedia(videoEl)

      hls.on(Hls.Events.MANIFEST_PARSED, () => {
        // console.log(video.name, 'Hls.Events.MANIFEST_PARSED')
        setManifestParsed(true)
      })

      hls.on(Hls.Events.ERROR, (event, data) => {
        log(data)
        if (data.fatal) {
          switch (data.type) {
            case Hls.ErrorTypes.NETWORK_ERROR:
              // try to recover network error
              // console.error('fatal network error encountered, try to recover')
              hls.startLoad()
              break
            case Hls.ErrorTypes.MEDIA_ERROR:
              // console.error('fatal media error encountered, try to recover')
              hls.recoverMediaError()
              break
            default:
              // cannot recover
              // console.error(data)
              log('unrecoverable error')
              hls.destroy()
              break
          }
        }
      })

      mux.monitor(videoEl, {
        debug: false,
        hlsjs: hls,
        Hls: Hls,
        data: {
          ...muxBaseConfig,

          player_name: 'VideoPlayerPublicHLS', // any arbitrary string you want to use to identify this player

          // Video Metadata
          video_id: stream.url, // ex: 'abcd123'
          video_title: `${project.slug} - ${stream.name} - LIVE`, // ex: 'My Great Video'
          // video_series: '', // ex: 'Weekly Great Videos'
          video_stream_type: 'live', // 'live' or 'on-demand',
          video_content_type: 'live video',
        },
      })

      setHlsPlayer(hls)
    } else if (videoEl && stream.url) {
      // MSE not supported on iOS but can run hls natively
      videoEl.src = stream.url
      tryPlay()
      setManifestParsed(true)
    }
  }, [videoEl, stream.url, tryPlay, log])

  // control all plays/pauses in here to minimize conflicts
  const shouldPlay =
    shouldPlayProp &&
    videoEl &&
    manifestParsed &&
    isVisible &&
    (disableIntersectionObserver || intersection?.isIntersecting) &&
    !isTouchDevice

  useEffect(() => {
    if (!videoEl) return
    if (shouldPlay) {
      log('shouldPlay setBuffering(true)')
      setBuffering(true)
      tryPlay()
    } else {
      videoEl.pause()
    }
  }, [videoEl, shouldPlay, tryPlay, log])

  // dispose player on unmount
  useUnmount(() => {
    if (hlsPlayer) {
      hlsPlayer.destroy()
    }
  })

  useEffect(() => {
    if (!showModal) {
      setEnlargeWithPtzState(false)
    }
  }, [showModal])
  // end side effects

  // subtract 40 for control bar height, projectNavHeight for nav
  const parentAspectRatio =
    parentWidth / (window.innerHeight - 40 - useTheme().custom.projectNavHeight)

  const videoAspectRatio = disableRotation
    ? stream.aspectRatioNumber
    : stream.rotatedAspectRatioNumber

  const containerAspectRatio =
    stream.width === 1
      ? Math.max(parentAspectRatio, videoAspectRatio)
      : videoAspectRatio

  const containerStyle = {
    paddingTop: (1 / containerAspectRatio) * 100 + '%',
  }

  const videoStyle =
    stream.rotation % 180 === 90 && !disableRotation
      ? {
          width: `${stream.aspectRatioNumber * 100}%`,
          height: `${(1 / stream.aspectRatioNumber) * 100}%`,
          top: '50%',
          left: '50%',
          transform: `translate(-50%, -50%) rotate(${stream.rotation}deg)`,
        }
      : { width: '100%', height: '100%', top: 0, left: 0 }

  const poster = () => (
    <div
      className={clsx(classes.layer, classes.poster)}
      style={{
        backgroundImage: `url(${stream.current_thumbnail_url})`,
      }}
    ></div>
  )

  log('showModal', showModal)

  return (
    <ThemeProvider theme={darkTheme}>
      <Paper className={clsx(className, classes.root)}>
        <div
          ref={videoContainerRef}
          className={classes.videoContainer}
          style={containerStyle}
        >
          {!liveStatus && poster()}
          <video
            ref={videoRef}
            className={classes.video}
            style={videoStyle}
            autoPlay
            muted
            onCanPlay={() => {
              log('onCanPlay setBuffering(false)')
              setBuffering(false)
            }}
          />
          {stream.show_still_image && poster()}
          {!stream.show_still_image && buffering && !isTouchDevice && (
            <div className={classes.layer}>
              <Loading
                text={'Loading stream...'}
                className={classes.loadingSpinner}
              ></Loading>
            </div>
          )}
          {isTouchDevice &&
            (!stream.computed_error_messages ||
              stream.computed_error_messages?.length === 0) && (
              <div
                className={classes.overlay}
                onClick={() => {
                  if (videoEl && isTouchDevice) {
                    if (videoEl.requestFullscreen) {
                      videoEl.requestFullscreen()
                    } else if (videoEl.webkitEnterFullscreen) {
                      videoEl.webkitEnterFullscreen()
                    }
                    tryPlay()
                  }
                }}
              >
                <PlayCircleFilledIcon
                  style={{ color: '#fff', width: 80, height: 80 }}
                />
              </div>
            )}
          <LivePlayerErrorMessages stream={stream} liveStatus={liveStatus} />
        </div>
        {videoEl && (
          <Dialog
            open={showModal}
            onClose={() => {
              setEnlargeWithPtzState(false)
              setModalStreamID && setModalStreamID()
              setSlideshowState && setSlideshowState(false)
            }}
            fullWidth
            maxWidth="xl"
            style={{ height: '100%' }}
            classes={{
              paper: classes.dialogPaper,
            }}
            transitionDuration={0}
          >
            <IconButton
              size="medium"
              color="inherit"
              onClick={() => {
                setEnlargeWithPtzState(false)
                setModalStreamID && setModalStreamID()
                setSlideshowState && setSlideshowState(false)
              }}
              style={{ position: 'absolute', top: 0, right: 0, zIndex: 1 }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </Dialog>
        )}
      </Paper>
    </ThemeProvider>
  )
}
