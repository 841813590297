export const prefabLoads = [
  'PREMUR',
  'POSE PREMUR',
  'COULAGE PREMUR',
  'DECHARGEMENT PREMUR',
  'RACK A PREMUR',
  'VOILE PREFA',
  'POSE VOILE PREFA',
  'DECHARGEMENT VOILE',
  'RACK A VOILE',
  'VOILE BOIS',
  'POSE VOILE BOIS',
  'DECHARGEMENT VOILE BOIS',
  'DECHARGEMENT/POSE VOILE BOIS',
  'RETOURNEUR FACADE',
  'POTEAUX',
  'POSE POTEAUX',
  'DECHARGEMENT POTEAUX',
  'REGLAGE OSSABOIS',
  'OSSABOIS',
  'DECHARGEMENT OSSABOIS',
  'DALLE',
  'POSE DALLE',
  'DECHARGEMENT DALLE',
  'DECHARGEMENT/POSE DALLE',
  'POUTRE',
  'POSE POUTRE',
  'DECHARGEMENT POUTRE',
  'DECHARGEMENT/POSE POUTRE',
  'COULAGE POUTRE',
  'ESCALIER',
  'POSE ESCALIER',
  'DECHARGEMENT ESCALIER',
  'DECHARGEMENT/POSE ESCALIER',
  'DALLES ALVEOLAIRES',
  'POSES DALLES ALVEOLAIRES',
  'DECHARGEMENT DALLES ALVEOLAIRES',
  'DECHARGEMENT/POSE DALLES ALVEOLAIRES',
]

export const actions = [
  'set',
  'strip',
  'strip_set',
  'move_fab',
  'move',
  'move_bundle',
  'delivery',
  'bundle_delivery',
  'delivery_set',
  'pour',
]


